export const getCenterCoordinates = () => {
  const center: any = {
    lat: 38.6915639,
    lng: 140.3200331
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 14
  return zoom
}
